import store from '@tenant/core/init/store'
import { GUARD, USER_ACCOUNT_TYPES } from '@tenant/utils/constants'

const guestGuard = async (to, from, next) => {
    if (to.meta.guard === GUARD.GUEST) {
        if (store.getters.hasTenantToken) {
            next('/')

            return
        }

        if (store.getters.hasCentralToken) {
            if (store.getters.hasCentralRole(USER_ACCOUNT_TYPES.ACCOUNTANT)) {
                next({ name: 'accountant.clients' })

                return
            }

            next({ name: 'auth.login-company' })

            return
        }
    }

    next()
}

export default guestGuard
